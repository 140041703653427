import styled, { keyframes } from 'styled-components';

import Icon from '../Icon';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Circle = styled.span<{ color?: string }>`
  /* border: 0.2em solid ${(p) => p.theme.color.lightEarth}; */
  border-top: 0.5em solid ${(p) => p.theme.color.earth};
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: ${spin} 0.6s linear infinite;
`;

export const StyledIcon = styled(Icon)`
  > svg {
    animation: ${spin} 0.6s linear infinite;
  }
`;
