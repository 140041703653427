import { FormikProps } from 'formik';
import { Block, Button, Form, FormCard, Input, Text } from '../../../components';

interface Props {
  formik: FormikProps<any>;
  isButtonDisabled: boolean;
}

const ConfirmPasswordRecovery: React.FC<Props> = ({ formik, isButtonDisabled }) => (
  <Form
    onSubmit={formik.handleSubmit}
    display="flex"
    direction="column"
    justify="space-evenly"
    align="center"
    width="100%"
    pt="xl"
    pb="xl"
  >
    <FormCard>
      <Block width="100%" display="flex" direction="column" align="center" justify="center">
        <Block display="flex" width="100%" justify="center" pt="s" pb="s">
          <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
            Nueva contraseña
          </Text>
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Email
          </Text>
          <Input
            onChange={formik.handleChange}
            type="text"
            name="email"
            value={formik.values.email}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.email && (
            <Block display="flex" width="100%" justify="center">
              <Text fontSize="s" color="red">
                {formik.errors.email as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Contraseña
          </Text>
          <Input
            onChange={formik.handleChange}
            type="text"
            name="password"
            value={formik.values.password}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.password && (
            <Block display="flex" width="100%" justify="center">
              <Text fontSize="s" color="red">
                {formik.errors.password as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Confirmar Contraseña
          </Text>
          <Input
            onChange={formik.handleChange}
            type="text"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.confirmPassword && (
            <Block display="flex" width="100%" justify="center">
              <Text fontSize="s" color="red">
                {formik.errors.confirmPassword as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" justify="center" mt="m" pt="s" pb="s" width="100%">
          <Button
            text="Enviar"
            type="submit"
            disabled={isButtonDisabled}
            variant="default"
            color="earth"
            size="m"
            fullWidth
            id="update_password_send"
          />
        </Block>
      </Block>
    </FormCard>
  </Form>
);

export default ConfirmPasswordRecovery;
