const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (!match) return '';
  return match[2];
}

export const haveAcceptedCookies = () => getCookie('cookies-consent') === 'true';
export const acceptOrDeclineCookies = (accepted = false) => setCookie('cookies-consent', accepted);
