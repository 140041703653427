import styled from 'styled-components';
import { Block } from '..';

export const StyledContainer = styled(Block)`
  border: 0.5px solid rgb(204, 204, 204);
  min-width: 19.75rem;
  max-width: 21.875rem;
  padding: 1rem;
  box-shadow: 0px 2px 12px 1px #c7c7c7;
`;
