import React from 'react';
import classes from './Video.module.scss';

interface IVideo {
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
}

const Video: React.FC<IVideo> = ({ src }) => {
  return (
    <div className={classes.video__container}>
      <video autoPlay loop muted controls src={src} playsInline className={classes.video__component} />
    </div>
  );
};

export default Video;
