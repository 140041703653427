import { ProductTypes } from './products.types';
import request from '../../utils/requestHandler';
import { DispatchAction } from '../store';
import container from '../../container';
import { ProductService } from '../../domain/product/product.service';

const productService = container.get<ProductService>('ProductService');

export const getProducts = (param: string) => async (dispatch: DispatchAction) => {
  try {
    dispatch(request.start(ProductTypes.FETCH_PRODUCTS_REQUEST));
    const products = await productService.getByCategory(param);
    dispatch(
      request.success(ProductTypes.FETCH_PRODUCTS_SUCCESS, {
        products,
      }),
    );
  } catch (error: any) {
    dispatch(request.failure(ProductTypes.FETCH_PRODUCTS_FAILURE, error.message));
  }
};

export const getSoldOutProducts = async (productsToCheck: Array<{ id: string; quantity: number }>) => {
  return productService.checkAvailability({ products: productsToCheck });
};
