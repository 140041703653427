import React from 'react';
import ErrorPage from '../../pages/404/ErrorPage';

interface Props {
  children?: JSX.Element | JSX.Element[];
  location?: string;
}

interface State {
  error?: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  componentDidUpdate(prevProps: { location: string | undefined }, prevState: any) {
    if (prevProps.location !== this.props.location && this.state.error) {
      this.setState({ error: false });
    }
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage />;
    }

    return children || null;
  }
}

export default ErrorBoundary;
