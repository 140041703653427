import { Block, CustomLink, PageContainer, Text } from '../../components';
import { routes } from '../../routes';

const ErrorPage = () => (
  <PageContainer pageTitle="Error 404">
    <Block display="flex" direction="column" width="100%" justify="center" align="center" pt="m" pr="m" pb="m" pl="m">
      <Text as="h3" color="earth" fontFamily="title" fontsize="m">
        Lo sentimos, la pagina que buscas no existe o no esta disponible.
      </Text>
      <CustomLink to={routes.BASE} hoverColor="lightSilver" fontSize="l">
        Volver al inicio
      </CustomLink>
      <CustomLink to={routes.STORE_DEPARTMENTS} hoverColor="lightSilver" fontSize="l">
        Ir a tienda
      </CustomLink>
    </Block>
  </PageContainer>
);

export default ErrorPage;
