import { Stepper as MuiStepper, StepIconProps } from '@material-ui/core';
import { Step as MuiStep } from '@material-ui/core';
import { StepLabel as MuiStepLabel } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import Block from '../Block';

interface Props {
  activeStep: number;
  stepLabels: Array<string>;
}

function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;
  const color = active || completed ? '#a78742' : '#a78742';
  // leave this hardcoded component pending refactor;
  return (
    <div
      style={{
        color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 22,
        width: 22,
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        border: `1px solid #a78742`,
      }}
    >
      {completed ? <CheckCircleOutline /> : props.icon}
    </div>
  );
}

const Stepper: React.FC<Props> = ({ activeStep, stepLabels }) => {
  const showAlternativeLabel = window.innerWidth <= 500;
  return (
    <Block width="100%">
      <MuiStepper activeStep={activeStep} alternativeLabel={showAlternativeLabel}>
        {stepLabels.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          //   if (isStepOptional(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }
          //   if (isStepSkipped(index)) {
          //     stepProps.completed = false;
          //   }
          return (
            <MuiStep key={label} {...stepProps}>
              <MuiStepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                {label}
              </MuiStepLabel>
            </MuiStep>
          );
        })}
      </MuiStepper>
    </Block>
  );
};

export default Stepper;
