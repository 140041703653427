import type { IHttpClient } from '../../AxiosApiClient';
import { IRepository } from '../base.repository';
import { DeliveryOptionItemDTO } from './delivery.dto';

export interface IDeliveryRepository extends IRepository {
  getOptions(): Promise<DeliveryOptionItemDTO[]>;
}

export class DeliveryRepository implements IDeliveryRepository {
  private endpoint: string = '/delivery';

  constructor(private httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async getOptions(): Promise<DeliveryOptionItemDTO[]> {
    return this.httpClient.get<DeliveryOptionItemDTO[]>(`${this.endpoint}/options`);
  }
}
