import { v4 } from 'uuid';

/**
 *
 * @param componentName component name to use as prefix
 * @returns string unique identifier for the component
 */
const uuidGenerator = (componentName: string) => `${componentName}_${v4()}`;

export default uuidGenerator;
