import { createAsyncThunk } from '@reduxjs/toolkit';
import container from '../../container';
import {
  CartUpdateAction,
  CartValidateDiscountRequestDTO,
  CreateCartRequestDTO,
  GetCartByCustomerDTO,
  GetCartResponseDTO,
  UpdateCartItemRequestDTO,
} from '../../domain/cart/cart.dtos';
import { CartService } from '../../domain/cart/cart.service';
import { StorageHandler } from '../../utils/StorageHandler';
import SnackbarAction from '../snackbar/snackbar.actions';
import { DefaultState } from '../store';
import { retrieveCart } from './cart.slice';

const cartService = container.get<CartService>('CartService');

export const createCart = createAsyncThunk<
  GetCartResponseDTO,
  { cartData: CreateCartRequestDTO },
  { rejectValue: string }
>('cart/create', async ({ cartData }, thunkApi) => {
  try {
    const createdCart: GetCartResponseDTO = await cartService.create(cartData);
    thunkApi.dispatch(SnackbarAction.success('Producto añadido al carrito'));
    StorageHandler.save('cart_id', createdCart?.id);
    return createdCart;
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const updateCartItems = createAsyncThunk<
  GetCartResponseDTO,
  { cartData: UpdateCartItemRequestDTO },
  { rejectValue: string }
>('cart/update-items', async ({ cartData }, thunkApi) => {
  try {
    const createdCart: GetCartResponseDTO = await cartService.updateItems(cartData);
    if (cartData.action === CartUpdateAction.ADD) {
      thunkApi.dispatch(SnackbarAction.success('Producto añadido al carrito'));
    } else {
      thunkApi.dispatch(SnackbarAction.error('Producto removido del carrito'));
    }
    return createdCart;
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const removeCart = createAsyncThunk('cart/remove', async (_, thunkApi) => {
  try {
    const state: DefaultState = thunkApi.getState() as DefaultState;
    if (state.cart?.cart) {
      await cartService.remove(state.cart?.cart?.id);
    }
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const getCart = createAsyncThunk('cart/get', async (_, thunkApi) => {
  try {
    const cartId = StorageHandler.retrieve('cart_id');
    if (cartId) {
      const cartOrNull = await cartService.get(cartId);
      if (cartOrNull && cartOrNull.id) {
        thunkApi.dispatch(retrieveCart(cartOrNull));
      } else {
        StorageHandler.clear('cart_id');
      }
    }
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const getCartByCustomerId = createAsyncThunk<{}, { data: GetCartByCustomerDTO }, { rejectValue: string }>(
  'cart/get-by-customer',
  async ({ data }, thunkApi) => {
    try {
      const cartOrNull = await cartService.getByCustomerId(data.customerId);
      if (cartOrNull && cartOrNull.id) {
        thunkApi.dispatch(retrieveCart(cartOrNull));
      } else {
        StorageHandler.clear('cart_id');
      }
    } catch (error) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  },
);

export const validateDiscountToCart = createAsyncThunk<
  GetCartResponseDTO,
  { cartData: CartValidateDiscountRequestDTO },
  { rejectValue: string }
>('cart/add-discount', async ({ cartData }, thunkApi) => {
  try {
    const createdCart: GetCartResponseDTO = await cartService.validateDiscount(cartData);
    return createdCart;
  } catch (error) {
    thunkApi.dispatch(SnackbarAction.error('El cupon a caducado o es invalido'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const addCustomerId = async (cartId: string) => cartService.addCustomerId(cartId);
