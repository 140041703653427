import { Block, Button, CustomLink, Text } from '../../components';
import { Product } from '../../domain/product/product.model';
import { ProductCard } from '../../components';
import { Wrapper } from './SearchResults.styles';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { routes } from '../../routes';

interface Props {
  isOpen: boolean;
  products?: Array<Product>;
  isMobile: boolean;
  onClose: () => void;
  showMoreHandler: () => void;
  showMoreButton: boolean;
}

const SearchResults: React.FC<Props> = ({ isOpen, products, isMobile, onClose, showMoreHandler, showMoreButton }) => {
  const history = useHistory();
  useEffect(() => {
    const unListen = history.listen(() => {
      onClose();
    });

    return () => {
      unListen();
    };
  }, [history.location.pathname]);
  return (
    <Wrapper isOpen={isOpen} isMobile={isMobile}>
      <Block>
        {products && products?.length > 0 ? (
          <Block display="flex" flexWrap="wrap" justify="space-between" align="center">
            {products &&
              products?.map((item, index) => <ProductCard productData={item} key={index} callBack={onClose} />)}
          </Block>
        ) : (
          <Block
            display="flex"
            direction="column"
            align="center"
            justify="flex-start"
            bgColor="white"
            pt="l"
            pr="s"
            pl="s"
            width="100%"
            height="100%"
          >
            <Text as="h2" color="earth" textAlign="center" fontsize="s" mb="m">
              No hay productos que coincidan
            </Text>
            <CustomLink to={routes.STORE_DEPARTMENTS}>Ir a tienda</CustomLink>
          </Block>
        )}
        {showMoreButton && (
          <Block display="flex" justify="center" align="center" width="100%">
            <Button text="Mostrar más" variant="outlined" size="m" color="earth" onClick={() => showMoreHandler()} />
          </Block>
        )}
      </Block>
    </Wrapper>
  );
};

export default SearchResults;
