export const ENV: NodeJS.ProcessEnv = process.env;
export const SUPPORT_MAIL_ADDRESS = 'soporte@sinaglife.com';
export const CONTACT_MAIL_ADDRESS = 'info@sinaglife.com';
export const environment = {
  NODE_ENV: ENV.NODE_ENV ?? 'development',
  API: ENV.REACT_APP_API ?? 'http://localhost:8080/v2',
  STRIPE_KEY: ENV.REACT_APP_STRIPE_KEY ?? '',
  STRIPE_KEY_TEST: ENV.REACT_APP_STRIPE_KEY_TEST ?? '',
  ORIGIN: ENV.REACT_APP_ORIGIN ?? 'http://localhost:3000',
  MIXPANEL_TOKEN: /* ENV.REACT_APP_MIXPANEL_TOKEN ??  */ 'debd1ecb3ca715dea6ecb505a3c477d8',
};
export const BASE_URL: string = environment.ORIGIN;
export const MIXPANEL_LIB_NAME = 'sinaglife';
