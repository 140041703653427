import React from 'react';
import { InputProps } from './Input.models';
import { InputContainer, Wrapper } from './Input.styles';

const Input: React.FC<InputProps> = (props) => (
  <Wrapper inputSize={props?.inputSize} type={props.type ?? 'text'} variant={props.variant} width={props.width}>
    <InputContainer {...props} focusable={props.focusable ?? true} />
  </Wrapper>
);

export default React.memo(Input);
