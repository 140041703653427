import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import CookieHandler from './utils/CookieHandler';
import { StorageHandler } from './utils/StorageHandler';
import { environment } from './constants';

export type Response<T> = AxiosResponse<T>;
export type RequestConfig = AxiosRequestConfig;
export class HttpError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export interface IHttpClient {
  get<T>(url: string, config?: RequestConfig): Promise<T>;
  post<T, R>(url: string, data?: T, config?: RequestConfig): Promise<R>;
  put<T, R>(url: string, data?: T, config?: RequestConfig): Promise<R>;
  delete<T>(url: string, config?: RequestConfig): Promise<T>;
}

type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export class AxiosHttpClient implements IHttpClient {
  private interceptors: any;
  private instance: AxiosInstance;
  private readonly BASE_URL: string = environment.API;
  private headers: { [key: string]: string } = {
    'Access-Control-Allow-Origin': environment.ORIGIN,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'application/json',
  };

  public constructor() {
    this.instance = axios.create({
      baseURL: this.BASE_URL,
      headers: this.headers,
      withCredentials: true,
    });
    this.interceptors = null;
    this._initializeResponseInterceptor();
    this.instance.interceptors.request.use(this._addSessionId);
    this.instance.interceptors.request.use(this._addJwt);
  }

  private _initializeResponseInterceptor = () => {
    this.interceptors = this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.instance.get(url, config);
  }

  public async post<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.post(url, data, config);
  }

  public async put<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.instance.put(url, data, config);
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.instance.delete(url, config);
  }

  public async customRequest<T, R>(
    method: HttpMethod,
    url: string,
    config?: AxiosRequestConfig,
    data?: T,
  ): Promise<Response<R>> {
    this.instance.interceptors.response.eject(this.interceptors);
    const response = await this.instance[method](url, data, config);
    this._initializeResponseInterceptor();
    return response;
  }

  private _handleResponse = ({ data, status, headers }: AxiosResponse) => {
    return data;
  };

  private _addJwt = (config: RequestConfig) => {
    const tokenOrNull = StorageHandler.retrieve('token');
    config.headers = {
      ...this.headers,
      ...(tokenOrNull && {
        Authorization: `Bearer ${tokenOrNull}`,
      }),
    };
    return config;
  };

  private _addSessionId = (config: RequestConfig) => {
    const sessionId = CookieHandler.get('sessionId');
    config.headers = {
      ...this.headers,
      ...(sessionId && {
        sessionId: `${sessionId}`,
      }),
    };
    return config;
  };

  protected _handleError = (error: any) => Promise.reject(error);
}
