import { SnackbarTypes } from './snackbar.types';

export interface ISnackbarState {
  openSnackbar: boolean;
  snackbarSuccess: boolean;
  snackbarMessage: string;
}

type SnackbarAction = {
  type: SnackbarTypes;
  payload: string;
};

const initialState = {
  openSnackbar: false,
  snackbarSuccess: false,
  snackbarMessage: '',
};

export const snackbarReducer = (state = initialState, action: SnackbarAction) => {
  switch (action.type) {
    case SnackbarTypes.SUCCESS:
      return {
        openSnackbar: true,
        snackbarSuccess: true,
        snackbarMessage: action.payload,
      };
    case SnackbarTypes.ERROR:
      return {
        ...state,
        openSnackbar: true,
        snackbarSuccess: false,
        snackbarMessage: action.payload,
      };
    case SnackbarTypes.CLEAR:
      return {
        ...state,
        openSnackbar: false,
      };
    default:
      return state;
  }
};
