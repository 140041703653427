import type { IHttpClient } from '../../AxiosApiClient';
import { IRepository } from '../base.repository';
import { ProcessPaymentRequestDTO } from './payment.dtos';

export interface IPaymentRepository extends IRepository {
  processPayment(data: ProcessPaymentRequestDTO): Promise<void>;
}

export class PaymentRepository implements IPaymentRepository {
  private httpClient: IHttpClient;
  private endpoint: string = '/payment';

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }
  async processPayment(data: ProcessPaymentRequestDTO): Promise<void> {
    return this.httpClient.post<ProcessPaymentRequestDTO, void>(`${this.endpoint}`, data);
  }
}
