import { useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Text, Block, Button, Form, FormCard, Input, Spinner, PasswordInput } from '../../../components';
import { DispatchAction, DefaultState } from '../../../redux/store';
import { CreateUserRequestDTO } from '../../../domain/user/user.dtos';
import { UserState } from '../../../redux/user/user.slice';
import { selectUserState } from '../../../redux/user/user.selectors';
import { registerUser } from '../../../redux/user/user.thunks';
import { getUserAge } from '../../../utils/functions';
import Register from './Register';

export interface FormikState {
  name: string;
  lastName: string;
  email: string;
  password: string;
  password2: string;
  birthDate: string;
}

const RegisterContainer = () => {
  const { user, isLoading } = useSelector<DefaultState, UserState>(selectUserState);
  const dispatch = useDispatch<DispatchAction>();
  const history = useHistory();

  useEffect(() => {
    if (user?.isConfirmed) {
      history.push('/');
    }
  }, [user, history]);

  const initialState: FormikState = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    birthDate: '',
  };

  function validator(values: FormikState) {
    const errors: Partial<FormikState> = {};
    if (!values.email) {
      errors.email = 'Email requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email invalido';
    }

    if (!values.password) {
      errors.password = 'Campo requerido';
    } else if (values.password.length < 5) {
      errors.password = 'Contraseña minimo 5 caracteres';
    }

    if (values.password !== values.password2) {
      errors.password = 'Las contraseñas deben coincidir';
    }

    if (values.birthDate === '' || getUserAge(values.birthDate) < 18) {
      errors.birthDate = 'Debes ser mayor de edad';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      const userData: CreateUserRequestDTO = {
        name: values.name,
        lastName: values.lastName,
        birthDate: values.birthDate,
        email: values.email,
        password: values.password,
      };
      dispatch(registerUser({ userData, formik }));
    },
    validate: validator,
  });

  if (formik.isSubmitting || isLoading) {
    return <Spinner top="25%" />;
  }

  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;

  const formData = (formik: FormikValues) => [
    {
      type: 'text',
      onChange: formik.handleChange,
      name: 'name',
      value: formik.values.name,
      label: 'Nombre',
    },
    {
      type: 'text',
      onChange: formik.handleChange,
      name: 'lastName',
      value: formik.values.lastName,
      label: 'Apellido',
    },
    {
      error: formik.errors.birthDate,
      type: 'date',
      onChange: formik.handleChange,
      name: 'birthDate',
      value: formik.values.birthDate,
      label: 'Fecha de nacimiento',
    },
    {
      error: formik.errors.email,
      type: 'email',
      onChange: formik.handleChange,
      name: 'email',
      value: formik.values.email,
      label: 'Email',
    },
    {
      error: formik.errors.password,
      type: 'password',
      onChange: formik.handleChange,
      name: 'password',
      value: formik.values.password,
      label: 'Contraseña',
    },
    {
      error: formik.errors.password2,
      type: 'password',
      onChange: formik.handleChange,
      name: 'password2',
      value: formik.values.password2,
      label: 'Repetir contraseña',
    },
  ];
  return <Register formik={formik} isButtonDisabled={isButtonDisabled} />;
};

export default RegisterContainer;
