const links = {
  EMAIL: 'mailto:info@sinaglife.com',
  FACEBOOK: 'https://www.facebook.com/sinaglife',
  INSTAGRAM: 'https://instagram.com/sinaglife?igshid=16k76b9ah91xo',
  GOOGLE:
    'https://www.google.es/maps/place/Sinag+Life/@40.4169335,-3.7083759,16z/data=!3m1!4b1!4m5!3m4!1s0xd4227b3d4b9dbfb:0xd7bb3a32270f36d7!8m2!3d40.4169336!4d-3.7039985?hl=es&authuser=0',
  WHATSAPP: 'https://api.whatsapp.com/send?phone=34611763823&text=¡Hola! Quisiera orientación con:',
  GOOGLE_MAPS:
    'https://www.google.es/maps/place/Sinag+Life/@40.4050433,-3.7207737,17z/data=!3m1!4b1!4m5!3m4!1s0xd4227b3d4b9dbfb:0xd7bb3a32270f36d7!8m2!3d40.4050433!4d-3.7207737?hl=es&authuser=0',
  CORREOS: 'https://www.correos.es/es/es/particulares',
  AEPD: 'https://www.aepd.es/es',
  STRIPE: 'https://stripe.com/es',
};

export default links;
