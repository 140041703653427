import styled, { css } from 'styled-components';
import { TextAreaContainerProps } from './TextArea.models';

const sizeValues = {
  xs: ``,
  s: '50px',
  m: '100px',
  l: '150px',
};

const setBorder = () => css`
  border: 1px solid ${(p) => p.theme.color.hBorder};
`;

export const Container = styled.textarea<TextAreaContainerProps>`
  width: 100%;
  box-sizing: border-box;
  height: ${(p) => sizeValues[p.inputSize]};
  font-family: ${(p) => p.theme.fontFamily.base};
  font-size: ${(p) => (p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.s)};
  ${setBorder()};
`;
