import { CreateOrderRequestDTO, CreateOrderResponseDTO } from './order.dtos';
import { IOrderRepository } from './order.repository';

export class OrderService {
  private orderRepository: IOrderRepository;

  constructor(orderRepository: IOrderRepository) {
    this.orderRepository = orderRepository;
  }

  async create(data: CreateOrderRequestDTO): Promise<CreateOrderResponseDTO> {
    return this.orderRepository.create(data);
  }
}
