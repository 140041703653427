import { RegisterClientToNewsletterRequestDTO } from './newsletter.dto';
import { INewsLetterRepository } from './newsletter.repository';

export class NewsLetterService {
  constructor(private NewsLetterRepo: INewsLetterRepository) {
    this.NewsLetterRepo = NewsLetterRepo;
  }

  async registerClient(data: RegisterClientToNewsletterRequestDTO): Promise<void> {
    return this.NewsLetterRepo.registerClient(data);
  }
}
