import styled from 'styled-components';
import { margin } from '../../theme';
import { TextContainerProps } from './Text.models';

export const TexContainer = styled.span<TextContainerProps>`
  text-transform: ${(p) => (p.isUppercase ? 'uppercase' : null)};
  text-align: ${(p) => p.textAlign ?? 'justify'};
  color: ${(p) => (p.color ? p.theme.color[p.color] : p.theme.color.default)};
  font-family: ${(p) => p.fontFamily && p.theme.fontFamily[p.fontFamily]};
  text-overflow: ${(p) => p.textOverFlow ?? null};
  overflow: ${(p) => p.overFlow ?? null};
  white-space: ${(p) => p.whiteSpace ?? null};
  margin: 0;
  cursor: ${(p) => p.cursor && p.cursor};
  ${margin};
  ${(p) => p.theme.fontSizeGenerator(p.as, p.fontsize ?? 'm')}
  font-weight: ${(p) => p.weight && p.theme.weight[p.weight]};
  ${(p) => p.customStyles && p.customStyles}
`;
