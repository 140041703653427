import moment from 'moment';

export const formatAmount = (amount, dec = 2) => {
  if (typeof amount !== 'number') return parseFloat(amount).toFixed(dec);
  return amount.toFixed(dec);
};

export const getUserAge = (birthDate) => {
  const today = moment();
  const userAge = today.diff(birthDate, 'years');
  return userAge;
};
