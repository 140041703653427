import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import { routes } from '../../routes';
import CheckoutPage from './CheckoutPage';

const CheckoutRoutes = () => (
  <ErrorBoundary>
    <Switch>
      <Route path={routes.CHECKOUT}>
        <CheckoutPage />
      </Route>
    </Switch>
  </ErrorBoundary>
);

export default CheckoutRoutes;
