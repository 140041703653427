import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components';
import { DispatchAction, DefaultState } from '../../../redux/store';
import { confirmPasswordRecovery } from '../../../redux/user/user.thunks';
import { UserState } from '../../../redux/user/user.slice';
import { selectUserState } from '../../../redux/user/user.selectors';
import ConfirmPasswordRecovery from './ConfirmPasswordRecovery';

export interface UpdatePasswordFormikState {
  email: string;
  password: string;
  confirmPassword: string;
}

const ConfirmPasswordRecoveryContainer = () => {
  const { isLoading } = useSelector<DefaultState, UserState>(selectUserState);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch<DispatchAction>();

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token, history]);

  const initialState = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  function validator(values: UpdatePasswordFormikState) {
    const errors: Partial<UpdatePasswordFormikState> = {};

    if (!values.email) {
      errors.email = 'Email requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email invalido';
    }

    if (!values.password) {
      errors.password = 'Campo requerido';
    }
    if (values.password.length < 5) {
      errors.password = 'Contraseña minimo 5 caracteres';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Campo requerido';
    }
    if (values.confirmPassword.length < 5) {
      errors.confirmPassword = 'Contraseña minimo 5 caracteres';
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Las contraseñas deben coincidir';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        newPassword: values.password,
        token,
      };

      dispatch(confirmPasswordRecovery({ userData: data, formik }));
    },

    validate: validator,
  });

  if (formik.isSubmitting || isLoading) {
    return <Spinner top="25%" />;
  }
  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;

  return <ConfirmPasswordRecovery formik={formik} isButtonDisabled={isButtonDisabled} />;
};

export default ConfirmPasswordRecoveryContainer;
