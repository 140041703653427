import { AnchorProps } from './Anchor.models';
import { Wrapper } from './Anchor.styles';

const Anchor: React.FC<AnchorProps> = (props) => (
  <Wrapper {...props} target="_blank" rel="noopener noreferrer">
    {props.children}
  </Wrapper>
);

export default Anchor;
