import { createSlice } from '@reduxjs/toolkit';
import { Checkout, DeliveryOptionSlug } from '../../domain/checkout/checkout.model';
import { getDeliveryAmount } from '../../pages/checkout/components/DeliveryOptions/delivery.utils';
import {
  GoToCheckoutAction,
  SetDeliveryInfoAction,
  SetDeliveryOptionAction,
  SetGuessDataAction,
} from './checkout.types';
import { createOrderAction } from './checkout.thunks';

export interface CheckoutState extends Checkout {
  isLoading: boolean;
}

export const initialState: CheckoutState = {
  cartId: '',
  products: [],
  discount: 0,
  subTotal: 0,
  total: 0,
  deliveryInfo: {
    address: '',
    zipCode: '',
    city: '',
    country: '',
    phone: '',
  },
  deliveryOption: {
    type: DeliveryOptionSlug.REGULAR,
    amount: getDeliveryAmount(DeliveryOptionSlug.REGULAR),
  },
  isGuess: false,
  guessData: undefined,
  isLoading: false,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setInitialCheckoutState(state, action: GoToCheckoutAction) {
      const { cartId, products, discount, subTotal, total, isUser } = action.payload;
      state.cartId = cartId;
      state.products = products;
      state.discount = discount;
      state.subTotal = subTotal;
      state.total = total;
      if (isUser) {
        state.guessData = undefined;
        state.isGuess = false;
      }
    },
    setGuessData(state, action: SetGuessDataAction) {
      state.guessData = action.payload;
      state.isGuess = true;
    },
    setDeliveryInfo(state, action: SetDeliveryInfoAction) {
      const { address, zipCode, city, country, phone } = action.payload;
      state.deliveryInfo = {
        address,
        zipCode,
        city,
        country,
        phone,
      };
    },
    setDeliveryOption(state, action: SetDeliveryOptionAction) {
      const { type, amount } = action.payload;
      // const formerDeliveryType = state.deliveryOption.type;
      // const formerDeliveryAmount = state.deliveryOption.amount;
      // const hasChanged = formerDeliveryType !== type && formerDeliveryAmount !== amount;
      // if (hasChanged) {
      //     state.total = amount === 0 ? state.subTotal - formerDeliveryAmount : state.subTotal + amount;
      // }
      state.deliveryOption = {
        type,
        amount,
      };
    },
    calculateTotal(state) {
      state.total = state.subTotal + state.deliveryOption.amount - state.discount;
    },
  },
  extraReducers(builder) {
    builder.addCase(createOrderAction.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(createOrderAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderId = action.payload;
    });
    builder.addCase(createOrderAction.rejected, (state, _) => {
      state.isLoading = false;
    });
  },
});

export const { setInitialCheckoutState, setGuessData, setDeliveryInfo, setDeliveryOption, calculateTotal } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
