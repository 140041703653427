import styled from 'styled-components';

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderContainer = styled.div<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  overflow: hidden;
`;

export const Slide = styled.div<{ image: string; translateX: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translateX}px);
`;

export const BottomContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-100%);
  font-size: 1rem;
  cursor: pointer;
  background: transparent;
`;

export const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
`;

export const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
`;
