import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  Block,
  Button,
  Form,
  FormCard,
  Input,
  Spinner,
  CustomLink,
  PasswordInput,
  Anchor,
} from '../../../components';
import { validator } from './singin.data';
import { DispatchAction, DefaultState } from '../../../redux/store';
import { login } from '../../../redux/user/user.thunks';
import { selectUserState } from '../../../redux/user/user.selectors';
import { UserState } from '../../../redux/user/user.slice';
import { routes } from '../../../routes';

export interface SingInFormikState {
  email: string;
  password: string;
}

const SingIn = () => {
  const history = useHistory<any>();
  const redirectPath = history.location?.state?.from ?? '/';
  const { user, isLoading } = useSelector<DefaultState, UserState>(selectUserState);
  const dispatch = useDispatch<DispatchAction>();

  useEffect(() => {
    if (user?.isConfirmed) history.push(redirectPath);
  }, [user, history, redirectPath]);

  const initialState: SingInFormikState = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      const userData = { email: values.email, password: values.password };
      dispatch(login({ userData, history }));
    },
    validate: validator,
  });

  if (isLoading || formik.isSubmitting) return <Spinner top="25%" />;
  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;

  return (
    <Form
      onSubmit={formik.handleSubmit}
      display="flex"
      direction="column"
      justify="space-evenly"
      align="center"
      width="100%"
      pt="xl"
      pb="xl"
    >
      <FormCard>
        <Block width="100%" display="flex" direction="column" align="center" justify="center">
          <Block display="flex" width="100%" justify="center" pt="s" pb="s">
            <Text color="black" as="h3" weight="semiBold" fontsize="s" isUppercase>
              Entrar
            </Text>
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Email
            </Text>
            <Input
              onChange={formik.handleChange}
              type="email"
              name="email"
              value={formik.values.email}
              variant="outlined"
              inputSize="m"
            />
            {formik.errors.email && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {formik.errors.email}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Contraseña
            </Text>
            <PasswordInput onChange={formik.handleChange} name="password" value={formik.values.password} />
            {formik.errors.password && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {formik.errors.password}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" align="flex-end" width="100%">
            <Block display="flex" justify="center" pt="m" pb="m" width="100%">
              <Button
                text="Aceptar"
                type="submit"
                disabled={isButtonDisabled}
                variant="default"
                color="xLightSilver"
                size="m"
                fullWidth
                id="sign_in_accept"
              />
            </Block>
            <Block display="flex" width="100%" pt="m" pb="m">
              <Anchor href={`${routes.GOOGLE_LOGIN}`} display="flex" width="100%">
                <Button
                  text="Continuar con Google"
                  size="m"
                  variant="default"
                  color="xLightSilver"
                  icon="google"
                  iconSize="s"
                  fullWidth
                  id="sign_in_with_google"
                  onClick={() => null}
                />
              </Anchor>
            </Block>
            <Block display="flex" justify="center" pb="s">
              <CustomLink fontSize="m" hoverColor="darkSilver" to={routes.FORGOT_PASSWORD} align="center">
                ¿Olvido su contraseña?
              </CustomLink>
            </Block>
            <Block display="flex" justify="center" pb="s">
              <CustomLink fontSize="m" hoverColor="darkSilver" to={routes.REGISTER} align="center">
                ¿Aun no tienes cuenta?
              </CustomLink>
            </Block>
          </Block>
        </Block>
      </FormCard>
    </Form>
  );
};

export default SingIn;
