import { IHttpClient } from '../../AxiosApiClient';
import { CreateOrderRequestDTO, CreateOrderResponseDTO } from './order.dtos';

export interface IOrderRepository {
  create(data: CreateOrderRequestDTO): Promise<CreateOrderResponseDTO>;
}

export class OrderRepository implements IOrderRepository {
  private httpClient: IHttpClient;
  private endpoint: string = '/orders';

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async create(data: CreateOrderRequestDTO): Promise<CreateOrderResponseDTO> {
    return this.httpClient.post(`${this.endpoint}`, data);
  }
}
