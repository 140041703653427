import { Block, Text, Button, CustomCircularProgress, Modal } from '../../../../components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  isSubmitting: boolean;
}

const ProcessPaymentModal: React.FC<Props> = ({ isOpen, onClose, title, text, isSubmitting }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Block
      display="flex"
      direction="column"
      pt="s"
      pr="l"
      pb="s"
      pl="l"
      customStyles={{ minWidth: '300px', maxWidth: '350px' }}
    >
      <Block display="flex" justify="center" align="center" pt="s" pb="s">
        <Text as="h2" fontsize="s" fontFamily="title" color="earth">
          {title}
        </Text>
      </Block>
      <Block display="flex" justify="center" pt="s" pb="s">
        <Text as="p">{text}</Text>
      </Block>
      <Block display="flex" justify="center" width="100%">
        {isSubmitting ? (
          <CustomCircularProgress />
        ) : (
          <Block display="flex" pt="s" pb="s">
            <Button text="Cerrar" type="button" variant="default" color="earth" size="m" onClick={() => onClose()} />
          </Block>
        )}
      </Block>
    </Block>
  </Modal>
);

export default ProcessPaymentModal;
