import React from 'react';
import { DrawerProps } from './Drawer.models';
import { Wrapper } from './Drawer.styles';

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  children,
  position = 'left',
  pt,
  pr,
  pb,
  pl,
  width,
  height,
  bgColor,
}) => (
  <Wrapper
    width={width}
    height={height}
    isOpen={isOpen}
    position={position}
    bgColor={bgColor}
    pt={pt}
    pr={pr}
    pb={pb}
    pl={pl}
  >
    {children}
  </Wrapper>
);

export default Drawer;
