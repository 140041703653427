import styled from 'styled-components';
import ListContainer from '../ListContainer';

const transitionSpeed = '0.3s';

export const UL = styled(ListContainer)<{ isCollapse: boolean }>`
  height: ${(p) => (p.isCollapse ? 'auto' : '0px')};
  overflow: visible;
  visibility: ${(p) => (p.isCollapse ? 'visible' : 'hidden')};
  transition: height ${transitionSpeed} ease;
`;

export const DrawerHeader = styled.div``;
