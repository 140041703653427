import styled from 'styled-components';
import Block from '../Block/Block';

export const StyledBlock = styled(Block)<{ url: string }>`
  background-image: url(${(p) => p.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CustomText = styled.h2`
  width: 100%;
  color: ${(p) => p.theme.color.white};
  font-size: ${(p) => p.theme.fontSize.l};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-weight: ${(p) => p.theme.weight.bold};
  text-transform: uppercase;
  ${(p) => p.theme.fontSizeGenerator('h2', 'm')}
  margin-bottom: 1rem;
`;

export const StyledStrong = styled.strong`
  color: inherit;
  font-weight: ${(p) => p.theme.weight.bold};
  ${(p) => p.theme.fontSizeGenerator('h2', 's')};
  color: ${(p) => p.theme.color.black};
`;
