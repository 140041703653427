const requestHandler = {
  start: (type) => ({
    type,
  }),
  success: (type, data) => ({
    type,
    payload: data,
  }),
  failure: (type, error) => ({
    type,
    payload: error,
  }),
};

export default requestHandler;
