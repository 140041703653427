import { Block } from '..';
import { Circle } from './CircularProgress.styles';
import { Size } from '../../theme';

interface Props {
  size?: Size;
  color?: string;
}

const CustomCircularProgress: React.FC<Props> = ({ size, color }) => (
  <Block display="flex" align="center" justify="center">
    <Circle color={color} />
    {/* <StyledIcon icon="circle" size="m" color="earth"/> */}
  </Block>
);

export default CustomCircularProgress;
