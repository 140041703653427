import styled from 'styled-components';
import { inputPaddingsBySize } from '../Input/Input.styles';
import { ColorKeys, Size } from '../../theme';
import { IconPlacement, InputShape } from './IconInput';

export const Wrapper = styled.div<{
  size?: Size;
  iconPlacement: IconPlacement;
  shape: InputShape;
  bgColor?: ColorKeys;
}>`
  display: flex;
  flex-direction: ${(p) => (p.iconPlacement === 'start' ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: ${(p) => (p.shape === 'rounded' ? '4px' : null)};
  /* box-shadow: 1px 1px 5px ${(p) => p.theme.color.lightSilver}; */
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.5px;
  background-color: ${(p) => p.bgColor ?? 'trasnparent'};
  padding: ${(p) => (p.size ? inputPaddingsBySize[p.size] : inputPaddingsBySize['m'])};
`;
