import { Block, Button, Text } from '..';
import Modal from '../Modal';
import { acceptOrDeclineCookies } from '../../utils/cookies';
import { Img } from './CookiesConsentModal.styles';
import comet from '../../assets/images/footer/comet.webp';
import uuidGenerator from '../../utils/uuidGenerator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CookiesConsentModal: React.FC<Props> = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Block
      display="flex"
      direction="column"
      pt="s"
      pr="l"
      pb="s"
      pl="l"
      customStyles={{ minWidth: '340px', maxWidth: '440px' }}
    >
      <Block display="flex" justify="center" align="center">
        <Img src={comet} alt="cometa-sinaglife-accesorios" />
        <Text as="h2" fontsize="s" fontFamily="title" color="earth">
          Sinaglife
        </Text>
      </Block>
      <Block display="flex" pb="m">
        <Text as="p">
          Este sitio web utiliza las cookies necesarias para su funcionamiento, activando funciones básicas como la
          navegación y acceso a áreas seguras.
        </Text>
      </Block>
      <Block display="flex" justify="space-around" align="center" pt="s" pb="s">
        <Button
          onClick={() => {
            acceptOrDeclineCookies(true);
            onClose();
          }}
          text="Aceptar"
          variant="default"
          color="xLightSilver"
          size="m"
          id={uuidGenerator('button')}
        />
        <Button
          onClick={() => {
            acceptOrDeclineCookies(false);
            onClose();
          }}
          text="Declinar"
          variant="default"
          color="xLightSilver"
          size="m"
          id={uuidGenerator('button')}
        />
      </Block>
    </Block>
  </Modal>
);

export default CookiesConsentModal;
