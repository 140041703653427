import styled from 'styled-components';
import { flex, margin, padding } from '../../theme';
import { StyledAnchorProps } from './Anchor.models';

export const Wrapper = styled.a<StyledAnchorProps>`
  text-decoration: none !important;
  ${padding};
  ${margin};
  ${flex}
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  ${(p) => p.theme.fontSizeGenerator('a', p.fontSize ?? 'm')}
  color: ${(p) => (p.color ? p.theme.color[p.color] : p.theme.color.black)};
  &:hover {
    color: ${(p) => p.hoverColor && p.theme.color[p.hoverColor]} !important;
  }
`;
