import type { IHttpClient } from '../../AxiosApiClient';
import { Blog } from './blog.model';

export interface IBlogRepository {
  get(id: string): Promise<Blog>;
  getMany(): Promise<Array<Blog>>;
}

export class BlogRepository implements IBlogRepository {
  private httpClient: IHttpClient;
  private endpoint: string = '/blog';

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async get<Blog>(id: string): Promise<Blog> {
    return this.httpClient.get<Blog>(`${this.endpoint}/${id}`);
  }

  async getMany(): Promise<Array<Blog>> {
    return this.httpClient.get<Array<Blog>>(this.endpoint);
  }
}
