import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert: React.FC<any> = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export interface SnackBarProps {
  open: boolean;
  autoHideDuration?: number;
  close: () => void;
  text: string;
  success: boolean;
}

const SnackbarComponent: React.FC<SnackBarProps> = ({ open, autoHideDuration, text, success, close }) => (
  <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={close}>
    <Alert severity={success ? 'success' : 'error'}>{text}</Alert>
  </Snackbar>
);

export default SnackbarComponent;
