import { css } from 'styled-components';
import { Size } from './sizing';

export type TextTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'a' | 'span' | 'strong' | 'input';

export enum Weight {
  light = 300,
  regular = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
}

export type WeightKeys = keyof typeof Weight;

export enum FontFamily {
  base = 'Playfair Display, serif',
  base2 = 'EB Garamond, serif',
  title = 'Vidaloka, serif',
  link = 'Ubuntu Condensed, sans-serif',
  baseLabel = 'Forum, serif',
  homeSlogan = 'Parisienne, cursive',
}

export const defaultFontStyles: { [key in TextTags]: any } = {
  h1: {
    'font-size': {
      s: `2rem`,
      m: `2.375rem`,
      l: `3rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.2,
  },
  h2: {
    'font-size': {
      s: `1.5rem`,
      m: `1.875rem`,
      l: `2.25rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.2,
  },
  h3: {
    'font-size': {
      s: `1.125rem`,
      m: `1.25rem`,
      l: `1.5rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.3,
  },
  h4: {
    'font-size': {
      s: `1rem`,
      m: `1.125rem`,
      l: `1.25rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.4,
  },
  h5: {
    'font-size': {
      s: `0.875rem`,
      m: `1rem`,
      l: `1.125rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.5,
  },
  h6: {
    'font-size': {
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 600,
    'line-height': 1.6,
  },
  p: {
    'font-size': {
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 400,
    'line-height': 1.5,
  },
  a: {
    'font-size': {
      xs: `0.85rem`,
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 400,
    'line-height': 1.5,
  },
  span: {
    'font-size': {
      xs: `0.7rem`,
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 400,
    'line-height': 1.5,
  },
  strong: {
    'font-size': {
      xs: `0.7rem`,
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 400,
    'line-height': 1.5,
  },
  input: {
    'font-size': {
      s: `0.875rem`,
      m: `0.938rem`,
      l: `1rem`,
      xl: '',
    },
    'font-weight': 400,
    'line-height': 1.5,
  },
};
// TODO: check input font size values
export const wights: { [key in keyof typeof Weight]: string } = Object.keys(Weight).reduce((acc, key) => {
  const wightKey = key as keyof typeof Weight;
  return {
    ...acc,
    [wightKey]: Weight[wightKey],
  };
}, {} as { [key in keyof typeof Weight]: string });

export const fontFamilies = Object.keys(FontFamily).reduce((acc, key) => {
  const fontFamilyKey = key as keyof typeof FontFamily;
  return {
    ...acc,
    [fontFamilyKey]: FontFamily[fontFamilyKey],
  };
}, {} as { [key in keyof typeof FontFamily]: string });

export const fontSizeGenerator = (tag: TextTags, size: Size) => css`
  font-size: ${defaultFontStyles[tag]['font-size'][size]};
  font-weight: ${defaultFontStyles[tag]['font-weight']};
  line-height: ${defaultFontStyles[tag]['line-height']};
`;

export const fontStylesByTag = css`
  font-size: ${(p: { tag: TextTags; size: Size }) => defaultFontStyles[p.tag]['font-size'][p.size]};
  font-weight: ${(p: { tag: TextTags; size: Size }) => defaultFontStyles[p.tag]['font-weight'][p.size]};
  line-height: ${(p: { tag: TextTags; size: Size }) => defaultFontStyles[p.tag]['line-height'][p.size]};
`;

export const fontSize: { [key: string]: string } = {
  xs: '.5rem',
  s: '.8rem',
  m: '.875rem',
  l: '.9375rem',
  xl: '1.2rem',
  xxl: '2rem',
};
