import * as React from 'react';
import { Container } from './Icon.styles';
import content from './Icons';
import { IconProps } from './Icon.models';

const Icon: React.FC<IconProps> = (props) => (
  <Container {...props} data-testid="icon_test_id">
    {content[props.icon]}
  </Container>
);

export default Icon;
