import { SnackbarTypes } from './snackbar.types';

const success = (message: string) => ({
  type: SnackbarTypes.SUCCESS,
  payload: message,
});

const error = (message: string) => ({
  type: SnackbarTypes.ERROR,
  payload: message,
});

const clear = () => ({
  type: SnackbarTypes.CLEAR,
});

const SnackbarAction = {
  success,
  error,
  clear,
};

export default SnackbarAction;
