import { FormikProps } from 'formik';
import { Text, Block, Button, Form, FormCard, Input, CustomLink, PasswordInput, Anchor } from '../../../components';
import { FormikState } from './RegisterContainer';
import { routes } from '../../../routes';

export interface Props {
  formik: FormikProps<FormikState>;
  isButtonDisabled: boolean;
}

const Register: React.FC<Props> = ({ formik, isButtonDisabled }) => (
  <Form
    onSubmit={formik.handleSubmit}
    display="flex"
    direction="column"
    justify="space-evenly"
    align="center"
    width="100%"
    pt="xl"
    pb="xl"
  >
    <FormCard>
      <Block width="100%" display="flex" direction="column" align="center" justify="center">
        <Block display="flex" width="100%" justify="center" pt="s" pb="s">
          <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
            Registrarse
          </Text>
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Nombre
          </Text>
          <Input
            onChange={formik.handleChange}
            type="text"
            name="name"
            value={formik.values.name}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.name && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.name as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Apellido
          </Text>
          <Input
            onChange={formik.handleChange}
            type="text"
            name="lastName"
            value={formik.values.lastName}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.lastName && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.lastName as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Fecha de nacimiento
          </Text>
          <Input
            onChange={formik.handleChange}
            type="date"
            name="birthDate"
            value={formik.values.birthDate}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.birthDate && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.birthDate as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Email
          </Text>
          <Input
            onChange={formik.handleChange}
            type="email"
            name="email"
            value={formik.values.email}
            variant="outlined"
            inputSize="m"
          />
          {formik.errors.email && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.email as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Contraseña
          </Text>
          <PasswordInput
            btn_id={`show_password_password`}
            onChange={formik.handleChange}
            name="password"
            value={formik.values.password}
          />
          {formik.errors.password && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.password as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" width="100%" pt="s" pb="s">
          <Text as="h4" fontsize="s">
            Confirmar contraseña
          </Text>
          <PasswordInput
            btn_id={`show_password_password2`}
            onChange={formik.handleChange}
            name="password2"
            value={formik.values.password2}
          />
          {formik.errors.password2 && (
            <Block display="flex" width="100%" justify="center">
              <Text fontsize="s" color="red">
                {formik.errors.password2 as string}
              </Text>
            </Block>
          )}
        </Block>
        <Block display="flex" direction="column" align="flex-end" width="100%">
          <Block display="flex" direction="column" justify="center" align="center" pb="s">
            <Text fontsize="s" as="h6" weight="semiBold" color="black">
              Al registrarte, aceptas las politicas de privacidad y terminos de uso.
            </Text>
            <CustomLink fontSize="m" hoverColor="darkSilver" to={routes.PRIVACY_POLICY} align="center">
              Más Información
            </CustomLink>
          </Block>
          <Block display="flex" justify="center" pt="m" pb="m" width="100%">
            <Button
              text="Crear"
              type="submit"
              disabled={isButtonDisabled}
              variant="default"
              color="xLightSilver"
              size="m"
              fullWidth
              id="register_user_send"
            />
          </Block>
        </Block>
      </Block>
      <Anchor href={`${routes.GOOGLE_LOGIN}`} display="flex" width="100%">
        <Button
          text="Continuar con Google"
          size="m"
          variant="default"
          color="xLightSilver"
          icon="google"
          iconSize="s"
          fullWidth
          id="sign_in_with_google"
          onClick={() => null}
        />
      </Anchor>
    </FormCard>
  </Form>
);

export default Register;
