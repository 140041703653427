import Account from './Account';
import Burguer from './Burguer';
import Mail from './Mail';
import Messages from './Messages';
import Notifications from './Notifications';
import Search from './Search';
import DownArrow from './DownArrow';
import UpArrow from './UpArrow';
import RightArrow from './RightArrow';
import CloseMenu from './CloseMenu';
import CheckIcon from './CheckIcon';
import CloseMenuArrow from './CloseMenuArrow';
import SortUpArrow from './SortUpArrow';
import SortDownArrow from './SortDownArrow';
import DownFilledArrow from './DownFilledArrow';
import LeftArrow from './LeftArrow';
import Elipsis from './Elipsis';
import Eye from './Eye';
import EyeOff from './EyeOff';
import CloseX from './CloseX';
import HalfStar from './HalfStar';
import Star from './Star';
import Om from './Om';
import Blog from './Blog';
import Hands from './Hands';
import Store from './Store';
import Relax from './Relax';
import SoldOut from './SoldOut';
import SkinCare from './SkinCare';
import Add from './Add';
import Remove from './Remove';
import Delete from './Delete';
import Circle from './Circle';
import Basket from './Basket';
import MenuBars from './MenuBars';
import Marker from './Marker';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Whatsapp from './Whatsapp';
import Google from './Google';
import Twitter from './Twitter';

export default {
  account: <Account />,
  burguer: <Burguer />,
  mail: <Mail />,
  messages: <Messages />,
  notifications: <Notifications />,
  search: <Search />,
  upArrow: <UpArrow />,
  downArrow: <DownArrow />,
  rightArrow: <RightArrow />,
  leftArrow: <LeftArrow />,
  closeMenu: <CloseMenu />,
  checkIcon: <CheckIcon />,
  closeMenuArrow: <CloseMenuArrow />,
  sortUpArrow: <SortUpArrow />,
  sortDownArrow: <SortDownArrow />,
  downFilledArrow: <DownFilledArrow />,
  elipsis: <Elipsis />,
  eye: <Eye />,
  eyeOff: <EyeOff />,
  closeX: <CloseX />,
  halfStar: <HalfStar />,
  star: <Star />,
  om: <Om />,
  blog: <Blog />,
  store: <Store />,
  hands: <Hands />,
  relax: <Relax />,
  soldOut: <SoldOut />,
  skinCare: <SkinCare />,
  add: <Add />,
  remove: <Remove />,
  delete: <Delete />,
  circle: <Circle />,
  basket: <Basket />,
  menuBars: <MenuBars />,
  marker: <Marker />,
  facebook: <Facebook />,
  instagram: <Instagram />,
  whatsapp: <Whatsapp />,
  google: <Google />,
  twitter: <Twitter />,
};
