import { DeliveryOptionItem } from './delivery.model';
import { IDeliveryRepository } from './delivery.repository';

export class DeliveryService {
  constructor(private deliveryRepo: IDeliveryRepository) {
    this.deliveryRepo = deliveryRepo;
  }

  async getOptions(): Promise<Array<DeliveryOptionItem>> {
    return this.deliveryRepo.getOptions();
  }
}
