import { FormikValues } from 'formik';
import { SingInFormikState } from './SingIn';

function validator(values: SingInFormikState) {
  const errors: Partial<SingInFormikState> = {};
  if (!values.email) {
    errors.email = 'Email requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email invalido';
  }

  if (!values.password) {
    errors.password = 'Campo requerido';
  } else if (values.password.length < 5) {
    errors.password = 'Contraseña minimo 5 caracteres';
  }
  return errors;
}

const formData = (formik: FormikValues) => [
  {
    error: formik.errors.email,
    type: 'email',
    onChange: formik.handleChange,
    name: 'email',
    value: formik.values.email,
    label: 'Email',
  },
  {
    error: formik.errors.password,
    type: 'password',
    onChange: formik.handleChange,
    name: 'password',
    value: formik.values.password,
    label: 'Contraseña',
  },
];

export { validator, formData };
