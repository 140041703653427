import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../domain/user/user.models';
import {
  confirmPasswordRecovery,
  login,
  logOut,
  recoveryPassword,
  registerUser,
  removeUser,
  status,
  updateUser,
} from './user.thunks';

export interface UserState {
  user?: User;
  isLoading: boolean;
}

export const initialState: UserState = {
  user: undefined,
  isLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutSuccess(state) {
      state.user = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(login.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(status.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(registerUser.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(registerUser.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(updateUser.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateUser.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(recoveryPassword.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(recoveryPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(recoveryPassword.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(confirmPasswordRecovery.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(confirmPasswordRecovery.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(confirmPasswordRecovery.rejected, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(removeUser.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(removeUser.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(logOut.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(logOut.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = undefined;
    });
  },
});

export const { logoutSuccess } = userSlice.actions;
export default userSlice.reducer;
