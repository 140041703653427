/* eslint-disable no-unused-vars */
import React from 'react';
import { Wrapper } from './Backdrop.styles';
import { BackdropProps } from './Backdrop.models';
import Button from '../Button';
import Block from '../Block';
import uuidGenerator from '../../utils/uuidGenerator';

const Backdrop: React.FC<BackdropProps> = ({ isVisible, onClose, hasButton }) => (
  <Wrapper data-testid="backdrop_test_id" isVisible={isVisible} onClick={onClose}>
    {hasButton && (
      <Block>
        <Button
          variant="icon"
          icon="closeX"
          onClick={onClose}
          iconColor="white"
          iconSize="m"
          id={uuidGenerator('button')}
        />
      </Block>
    )}
  </Wrapper>
);
export default Backdrop;
