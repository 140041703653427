import styled, { css } from 'styled-components';
import { LabelPlacement } from './InputRow.models';
import { ExtendedSize, AlignItems } from '../../theme';

export const isCheckboxOrRadio = (type: string) => type === 'checkbox' || type === 'radio';

const setDirection = (placement: LabelPlacement) => {
  switch (placement) {
    case 'up':
      return css`
        flex-direction: column;
      `;
    case 'right':
      return css`
        flex-direction: row-reverse;
      `;
    case 'down':
      return css`
        flex-direction: column-reverse;
      `;
    default:
      return css`
        flex-direction: row;
      `;
  }
};

const setMainStyles = (type: string) => {
  switch (type) {
    case 'checkbox':
    case 'radio':
      return css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      `;
    default:
      return '';
  }
};

const labelAlignmentValues = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

export const MainContainer = styled.div<{ type?: string; width?: string }>`
  width: ${(p) => p.width ?? '100%'};
  ${(p) => p.type && setMainStyles(p.type)};
`;

export const Container = styled.div<{ labelPlacement?: LabelPlacement; alignment: AlignItems }>`
  display: flex;
  ${(p) => p.labelPlacement && setDirection(p.labelPlacement)};
  justify-content: ${(p) => p.alignment};
`;

export const ErrorLabel = styled.span<{ errorColor?: string; fontSize?: ExtendedSize }>`
  font-family: ${(p) => p.theme.fontFamily.base};
  color: ${(p) => (p.errorColor ? p.theme.color[p.errorColor] : p.theme.color.red)};
  ${(p) => p.theme.fontSizeGenerator('input', p.fontSize ?? 'm')}
`;
