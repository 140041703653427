import styled from 'styled-components';
import { SelectWrapperProps } from './Select.models';
import { Size } from '../../theme';

const sizes = {
  xs: ``,
  s: '200px',
  m: '350px',
  l: '500px',
};

const sizePaddings: { [key: string]: string } = {
  s: `0.188rem 0.563rem;`,
  m: `0.5rem 0.35rem`,
  l: `0.9rem 0.5rem`,
};

const setSize = (size: Size) => sizes[size] || '100%';

export const SelectWrapper = styled.select<SelectWrapperProps>`
  padding: 0.2rem 0.5rem;
  background-color: ${(p) => p.bgColor ?? p.theme.color.white};
  border: ${(p) => p.border && p.theme.borderVariants[p.border]};
  width: ${(p) => (!p.width ? p.selectSize && setSize(p.selectSize) : p.width)};
  padding: ${(p) => sizePaddings[p.size ?? 's']}
  font-size: ${(p) => p.theme.fontSize.m};
`;

export const OptionItems = styled.option``;
