import { Text, Block, Button, ListContainer, List } from '..';
import Modal from '../Modal';
import { ModalBaseProps } from '../Modal/Modal.models';

interface Props extends ModalBaseProps {
  soldOutProductNames: Array<string>;
  title: string;
  text: string;
}

const SoldOutProductModal: React.FC<Props> = ({ isOpen, onClose, soldOutProductNames, title, text }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Block
      display="flex"
      direction="column"
      align="center"
      pt="s"
      pr="l"
      pb="s"
      pl="l"
      customStyles={{ minWidth: '300px', maxWidth: '350px' }}
    >
      <Block display="flex" justify="center" align="center" pt="s" pb="s" width="100%">
        <Text as="h2" fontsize="s" fontFamily="title" color="earth">
          {title}
        </Text>
      </Block>
      <ListContainer listStyle="circle" align="flex-start" pt="s" pb="s" width="100%">
        {soldOutProductNames.map((text, index) => (
          <List key={index}>{text}</List>
        ))}
      </ListContainer>
      <Block display="flex" justify="flex-start" pt="s" pb="s" width="100%">
        <Text as="p">{text}</Text>
      </Block>
      <Block display="flex" pt="s" pb="s">
        <Button text="Cerrar" type="button" variant="default" color="xLightSilver" size="m" onClick={() => onClose()} />
      </Block>
    </Block>
  </Modal>
);

export default SoldOutProductModal;
