import { useState, useEffect } from 'react';
import { ProductCardContainer, ProductImage, StyledBlock, StyledProductName } from './ProductCard.styles';
import { formatAmount } from '../../utils/functions';
import { CustomLink, Text } from '..';
import { Product } from '../../domain/product/product.model';
import { Size } from '../../theme';
import { routes } from '../../routes';
import { formateEmptySpacesToUrl } from '../../utils';

interface Props {
  productData: Product;
  size?: Size;
  callBack?: () => void;
  category?: string;
}

const ProductCard: React.FC<Props> = ({ productData, size, category, callBack }) => {
  const [imgToShow, setImgToShow] = useState<string>('');
  const imgArray = productData?.images?.map((item) => item?.src);
  const altImg = productData?.images[0]?.alt;
  const formattedNameToUrl = formateEmptySpacesToUrl(productData?.name);
  const mainCategory = category ?? productData.categories[0];
  useEffect(() => {
    setImgToShow(productData?.images[0]?.src);
  }, [productData]);

  const getImgToShow = () => {
    if (imgArray.length > 1) {
      if (imgArray[1]) {
        if (imgToShow === imgArray[1]) {
          setImgToShow(imgArray[0]);
        } else {
          setImgToShow(imgArray[1]);
        }
      }
    }
  };
  return (
    <CustomLink
      to={{
        pathname: `${routes.STORE_PRODUCTS}/${formateEmptySpacesToUrl(mainCategory)}/${formattedNameToUrl}`,
        state: productData,
      }}
      hoverColor="earth"
      onClick={callBack}
    >
      <ProductCardContainer size={size ?? 'l'}>
        <ProductImage
          size={size ?? 'l'}
          onMouseEnter={getImgToShow}
          onMouseLeave={getImgToShow}
          src={imgToShow}
          alt={altImg}
        />
        <StyledBlock size={size ?? 'l'} display="flex" direction="column" align="center" pt="m">
          <StyledProductName size={size ?? 'l'} isUppercase fontsize={size ?? 'm'} textAlign="center">
            {productData?.name}
          </StyledProductName>
          <Text fontsize="m">{formatAmount(productData?.price, 2)}€</Text>
        </StyledBlock>
      </ProductCardContainer>
    </CustomLink>
  );
};

export default ProductCard;
