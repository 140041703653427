import { FormikValues } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Block, Text, Input, FormCard, Checkbox } from '../../../../components';
import { setGuessData } from '../../../../redux/checkout/checkout.slice';
import { DispatchAction } from '../../../../redux/store';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';

interface Props {
  data: FormikValues;
  handleSteps: (step: number) => void;
  currentStep: number;
}

const GuessData: React.FC<Props> = ({ data, handleSteps, currentStep }) => {
  const dispatch = useDispatch<DispatchAction>();
  const goNext = () => {
    dispatch(
      setGuessData({
        name: data?.values?.guessName,
        lastName: data?.values?.guessLastName,
        email: data?.values?.guessEmail,
        acceptNotifications: data?.values?.acceptNotifications,
      }),
    );
    handleSteps(currentStep + 1);
  };
  const { guessName, guessLastName, guessEmail } = data.values;
  const isButtonDisabled = !guessName || !guessLastName || !guessEmail;
  return (
    <FormCard mb="m">
      <Block display="flex" direction="column">
        <Block width="100%" display="flex" direction="column" align="center" justify="center">
          <Block display="flex" width="100%" justify="center" pt="s" pb="s">
            <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
              Continuar como invitado
            </Text>
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="p" weight="medium" fontsize="s">
              Nombre
            </Text>
            <Input
              onChange={data?.handleChange}
              type="text"
              name="guessName"
              value={data?.values?.guessName}
              variant="outlined"
              inputSize="s"
            />
            {data?.errors.values?.guessName && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {data?.errors.values?.guessName}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="p" weight="medium" fontsize="s">
              Apellido
            </Text>
            <Input
              onChange={data?.handleChange}
              type="text"
              name="guessLastName"
              value={data?.values?.guessLastName}
              variant="outlined"
              inputSize="s"
            />
            {data?.errors.values?.guessLastName && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {data?.errors.values?.guessLastName}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="p" weight="medium" fontsize="s">
              Email
            </Text>
            <Input
              onChange={data?.handleChange}
              type="email"
              name="guessEmail"
              value={data?.values?.guessEmail}
              variant="outlined"
              inputSize="s"
            />
            {data?.errors.values?.guessEmail && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {data?.errors.values?.guessEmail}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Block display="flex" width="90%" align="center">
              <Checkbox
                onChange={data?.handleChange}
                name="acceptNotifications"
                value={data?.values?.acceptNotifications}
                checked={data?.values?.acceptNotifications}
                inputSize="s"
              />
              <Text as="h5" weight="medium" fontsize="s" ml="s">
                Deseo recibir notificaciones
              </Text>
            </Block>
          </Block>
        </Block>
      </Block>
      <ButtonsContainer goBack={() => null} goNext={goNext} disabled={isButtonDisabled} />
    </FormCard>
  );
};

export default GuessData;
