import React from 'react';
import { Wrapper } from './CustomLink.styles';
import { Link } from 'react-router-dom';
import { CustomLinkProps } from './CustomLink.models';

const CustomLink: React.FC<CustomLinkProps> = (props) => (
  <Wrapper {...props}>
    <Link to={props.to}>{props?.children}</Link>
  </Wrapper>
);

export default CustomLink;
