import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormikProps } from 'formik';
import { History } from 'history';
import container from '../../container';
import {
  ConfirmRecoveryPasswordRequestDTO,
  CreateUserRequestDTO,
  RecoveryPasswordRequestDTO,
  RemoveRequestDTO,
  UserLoginRequestDTO,
  UserLoginResponseDTO,
  UserUpdateRequestDTO,
} from '../../domain/user/user.dtos';
import { User } from '../../domain/user/user.models';
import { UserService } from '../../domain/user/user.service';
import { StorageHandler } from '../../utils/StorageHandler';
import snackbarActions from '../snackbar/snackbar.actions';
import { routes } from '../../routes';
import { addCustomerId, getCart, getCartByCustomerId, removeCart } from '../cart/cart.thunks';
import { DefaultState } from '../store';
import { clearCart, setCustomerId } from '../cart/cart.slice';
import { LocationState } from '../../types';

const userService = container.get<UserService>('UserService');

export const login = createAsyncThunk<
  UserLoginResponseDTO,
  { userData: UserLoginRequestDTO; history: History<LocationState> },
  { rejectValue: string }
>('user/login', async ({ userData, history }, thunkApi) => {
  try {
    const response: User = await userService.logIn(userData);
    const state: DefaultState = thunkApi.getState() as DefaultState;

    if (!state.cart?.cart) {
      if (response && response.id) {
        thunkApi.dispatch(getCartByCustomerId({ data: { customerId: response.id } }));
      } else {
        thunkApi.dispatch(getCart());
      }
    } else if (!state.cart?.cart.customerId) {
      await addCustomerId(state.cart.cart.id);
      thunkApi.dispatch(setCustomerId(response.id));
    }
    StorageHandler.save('token', response.token);
    const redirectPath = history.location?.state?.from ?? routes.BASE;
    history.push(redirectPath);
    return response;
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Usuario o contraseña invalido'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const status = createAsyncThunk<UserLoginResponseDTO>('user/status', async (_, thunkApi) => {
  const response = await userService.checkStatus();
  if (response && response.id) {
    thunkApi.dispatch(getCartByCustomerId({ data: { customerId: response.id } }));
  } else {
    thunkApi.dispatch(getCart());
  }
  return response;
});

export const registerUser = createAsyncThunk<
  {},
  { userData: CreateUserRequestDTO; formik: FormikProps<any> },
  { rejectValue: string }
>('user/register', async ({ userData, formik }, thunkApi) => {
  try {
    await userService.create(userData);
    formik.resetForm();
    thunkApi.dispatch(snackbarActions.success('Registro exitoso, revise su bandeja de entrada o spam'));
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Lo sentimos algo ha ido mal'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const recoveryPassword = createAsyncThunk<
  {},
  { userData: RecoveryPasswordRequestDTO; formik: FormikProps<any> },
  { rejectValue: string }
>('user/recovery-password', async ({ userData, formik }, thunkApi) => {
  try {
    await userService.recoveryPassword(userData);
    formik.resetForm();
    thunkApi.dispatch(snackbarActions.success('Operacion exitosa, revise su bandeja de entrada o spam'));
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Lo sentimos algo ha ido mal'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const confirmPasswordRecovery = createAsyncThunk<
  {},
  { userData: ConfirmRecoveryPasswordRequestDTO; formik: FormikProps<any> },
  { rejectValue: string }
>('user/confirm-password-recovery', async ({ userData, formik }, thunkApi) => {
  try {
    await userService.confirmRecoveryPassword(userData);
    formik.resetForm();
    thunkApi.dispatch(snackbarActions.success('Operacion exitosa'));
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Lo sentimos algo ha ido mal'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const updateUser = createAsyncThunk<
  {},
  { userData: UserUpdateRequestDTO; formik: FormikProps<any> },
  { rejectValue: string }
>('user/update', async ({ userData, formik }, thunkApi) => {
  try {
    await userService.updateUser(userData);
    formik.resetForm();
    thunkApi.dispatch(snackbarActions.success('Operacion exitosa'));
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Lo sentimos algo ha ido mal'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const removeUser = createAsyncThunk<
  {},
  { userData: RemoveRequestDTO; formik: FormikProps<any> },
  { rejectValue: string }
>('user/remove', async ({ userData, formik }, thunkApi) => {
  try {
    await userService.remove(userData);
    thunkApi.dispatch(logOut());
    formik.resetForm();
    thunkApi.dispatch(snackbarActions.success('Operacion exitosa, revise su bandeja de entrada'));
  } catch (error) {
    thunkApi.dispatch(snackbarActions.error('Lo sentimos algo ha ido mal'));
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const logOut = createAsyncThunk<{}>('user/logOut', async (_, thunkApi) => {
  await userService.logOut();
  thunkApi.dispatch(clearCart());
  StorageHandler.clear('token');
  StorageHandler.clear('cart_id');
});
