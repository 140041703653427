import { DropOutFormikState } from './DropOut';

export const validator = (values: DropOutFormikState) => {
  const errors: Partial<DropOutFormikState> = {};
  if (!values.email) {
    errors.email = 'Email requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email invalido';
  }
  return errors;
};
