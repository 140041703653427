import React, { createContext, ReactNode } from 'react';
import { DeliveryService } from '../domain/delivery/delivery.service';
import { ProductService } from '../domain/product/product.service';
import { CartService } from '../domain/cart/cart.service';
import { BlogService } from '../domain/blog/blog.service';
import { CouponService } from '../domain/coupon/coupon.service';
import container from '../container';
import { PaymentService } from '../domain/payment/payment.service';
import { SliderService } from '../domain/slider/slider.service';
import { NewsLetterService } from '../domain/newsletter/newsletter.service';

interface ServicesContextType {
  deliveryService: DeliveryService;
  productService: ProductService;
  cartService: CartService;
  blogService: BlogService;
  couponService: CouponService;
  paymentService: PaymentService;
  sliderService: SliderService;
  newsLetterService: NewsLetterService;
}

const ServicesContext = createContext<ServicesContextType | undefined>(undefined);

interface ServicesProviderProps {
  children: ReactNode;
}

function ServicesProvider({ children }: ServicesProviderProps) {
  const deliveryService = container.get<DeliveryService>('DeliveryService');
  const blogService = container.get<BlogService>('BlogService');
  const cartService = container.get<CartService>('CartService');
  const couponService = container.get<CouponService>('CouponService');
  const productService = container.get<ProductService>('ProductService');
  const paymentService = container.get<PaymentService>('PaymentService');
  const sliderService = container.get<SliderService>('SliderService');
  const newsLetterService = container.get<NewsLetterService>('NewsLetterService');

  return (
    <ServicesContext.Provider
      value={{
        deliveryService,
        blogService,
        cartService,
        couponService,
        productService,
        paymentService,
        sliderService,
        newsLetterService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
}

export { ServicesContext, ServicesProvider };
