import { createAsyncThunk } from '@reduxjs/toolkit';
import container from '../../container';
import { CartItem } from '../../domain/cart/cart.model';
import { DeliveryInfo } from '../../domain/checkout/checkout.model';
import snackbarActions from '../snackbar/snackbar.actions';
import { DefaultState, DispatchAction, RootState } from '../store';
import { calculateTotal, setDeliveryInfo, setDeliveryOption, setInitialCheckoutState } from './checkout.slice';
import { SetDeliveryOption } from './checkout.types';
import { CreateOrderRequestDTO, CreateOrderResponseDTO } from '../../domain/order/order.dtos';
import { OrderService } from '../../domain/order/order.service';

const orderService = container.get<OrderService>('OrderService');
export const setInitialCheckoutStateAction = () => (dispatch: DispatchAction, getState: () => RootState) => {
  const cartOrNull = getState().cart?.cart ?? null;
  const userOrNull = getState().user?.user ?? null;
  if (!cartOrNull) return;
  dispatch(
    setInitialCheckoutState({
      cartId: cartOrNull?.id ?? '',
      products: cartOrNull?.items?.map((item: CartItem) => ({
        id: item.id,
        name: item.name,
        price: item.price,
        code: item.code,
        quantity: item.quantity,
        image: {
          src: item.image?.src ?? '',
          alt: item.image?.alt ?? '',
        },
      })),
      discount: cartOrNull?.discount ?? 0,
      subTotal: cartOrNull?.subTotal ?? 0,
      total: cartOrNull?.total ?? 0,
      isUser: Boolean(userOrNull),
    }),
  );
};

export const setDeliveryInfoAction = (deliveryInfo: DeliveryInfo) => (dispatch: DispatchAction) => {
  dispatch(setDeliveryInfo(deliveryInfo));
};

export const setDeliveryOptionAction = (deliveryOptionData: SetDeliveryOption) => (dispatch: DispatchAction) => {
  dispatch(setDeliveryOption(deliveryOptionData));
};

export const createOrderAction = createAsyncThunk<string, SetDeliveryOption, { rejectValue: string }>(
  'order/create',
  async (deliveryOptionData, thunkApi) => {
    try {
      thunkApi.dispatch(setDeliveryOption(deliveryOptionData));
      thunkApi.dispatch(calculateTotal());
      const state: DefaultState = thunkApi.getState() as DefaultState;

      if (!state.checkout || !state.checkout.cartId) {
        throw new Error(`Missing required params to continue`);
      }
      const { checkout } = state;
      const { cartId, isGuess, deliveryInfo, products, deliveryOption, discount, total, subTotal } = state.checkout;
      const response: CreateOrderResponseDTO = await orderService.create({
        cartId,
        phone: deliveryInfo.phone,
        isGuess,
        shippingData: deliveryInfo,
        items: products,
        deliveryOptionType: deliveryOption.type,
        subTotal,
        ...(checkout.guessData && { guessData: checkout.guessData }),
        discount,
        total,
        ...(checkout.orderId && { orderId: checkout.orderId }),
      });
      return response?.orderId;
    } catch (error) {
      thunkApi.dispatch(snackbarActions.error('There was an error'));
      return thunkApi.rejectWithValue((error as Error).message);
    }
  },
);
