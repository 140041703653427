import { DeliveryOptionSlug } from '../../../../domain/checkout/checkout.model';

export type DeliveryType = 'national' | 'international' | 'express' | 'recogida';

export const getDeliveryAmount = (value: DeliveryOptionSlug): number => {
  switch (value) {
    case DeliveryOptionSlug.REGULAR:
      return 5;
    case DeliveryOptionSlug.INTERNATIONAL:
      return 6;
    case DeliveryOptionSlug.EXPRESS:
      return 9;
    default:
      return 0;
  }
};

export const deliveryFormData: Array<{
  value: DeliveryOptionSlug;
  label: string;
}> = [
  {
    value: DeliveryOptionSlug.REGULAR,
    label: 'España, Peninsula y Baleares',
  },
  {
    value: DeliveryOptionSlug.INTERNATIONAL,
    label: 'Toda Europa',
  },
  {
    value: DeliveryOptionSlug.EXPRESS,
    label: 'Express',
  },
  {
    value: DeliveryOptionSlug.PICK_UP_D,
    label: 'C. Giralda 5 (28041)',
  },
  {
    value: DeliveryOptionSlug.PICK_UP_J,
    label: 'P.º Melancólicos 18 (28005)',
  },
];
