import moment from 'moment';
import { StorageHandler } from './StorageHandler';

export class SessionHandler {
  static readonly storagekey = 'visitHistory';

  static create() {
    const sessionData: SessionData = {
      id: '123456789',
      pageVisited: 1,
      startedAt: moment().format('YYYY-MM-DD'),
    };
    StorageHandler.save(this.storagekey, sessionData);
  }

  static incrementPageVisit() {
    const sessionData = StorageHandler.retrieve(this.storagekey) as SessionData;
    if (sessionData) {
      sessionData.pageVisited++;
      StorageHandler.save(this.storagekey, sessionData);
    }
  }
}

export interface SessionData {
  id: string;
  pageVisited: number;
  startedAt: string;
}
