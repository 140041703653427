import { useHistory } from 'react-router-dom';
import { User } from '../../domain/user/user.models';
import Modal from '../Modal';
import Block from '../Block';
import Button from '../Button';
import Text from '../Text';
import uuidGenerator from '../../utils/uuidGenerator';
import { routes } from '../../routes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user?: User;
  singOut: () => void;
}

const UserModal: React.FC<Props> = function ({ isOpen, onClose, user, singOut }) {
  const history = useHistory<any>();
  const goTo = (pathname: string, state?: any) => {
    history.push({
      pathname,
      state,
    });
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!user?.isConfirmed ? (
        <Block
          pt="l"
          pr="m"
          pb="l"
          pl="m"
          display="flex"
          direction="column"
          height="220px"
          align="center"
          justify="space-between"
          customStyles={{ minWidth: '250px', maxWidth: '440px' }}
        >
          <Button
            onClick={() => goTo(routes.LOGIN, { from: history.location.pathname })}
            text="Entrar"
            variant="default"
            fullWidth
            color="xLightSilver"
            size="m"
            id={uuidGenerator('button')}
          />
          <Text>Aun no tienes cuenta?</Text>
          <Button
            onClick={() => goTo(routes.REGISTER)}
            text="Registrarse"
            variant="default"
            fullWidth
            color="xLightSilver"
            size="m"
            id={uuidGenerator('button')}
          />
          <Button
            onClick={() => goTo(routes.DROP_OUT)}
            text="Darme de baja"
            variant="outlined"
            fullWidth
            color="lightSilver"
            size="m"
            id={uuidGenerator('button')}
          />
        </Block>
      ) : (
        <Block
          pt="l"
          pr="m"
          pb="l"
          pl="m"
          display="flex"
          direction="column"
          width="250px"
          height="220px"
          align="center"
          justify="space-between"
        >
          <Button
            onClick={() => goTo(routes.CHANGE_PASSWORD)}
            text="Cambiar contraseña"
            variant="default"
            fullWidth
            color="xLightSilver"
            size="m"
          />
          <Button
            onClick={() => {
              singOut();
              goTo('/');
            }}
            text="Salir"
            variant="default"
            fullWidth
            color="xLightSilver"
            size="m"
            id={uuidGenerator('button')}
          />
          <Button
            onClick={() => goTo(routes.DROP_OUT)}
            text="Darme de baja"
            variant="outlined"
            fullWidth
            color="lightSilver"
            size="m"
            id={uuidGenerator('button')}
          />
        </Block>
      )}
    </Modal>
  );
};

export default UserModal;
