import type { IHttpClient } from '../../AxiosApiClient';
import {
  ConfirmRecoveryPasswordRequestDTO,
  CreateUserRequestDTO,
  RecoveryPasswordRequestDTO,
  RemoveRequestDTO,
  UserLoginRequestDTO,
  UserLoginResponseDTO,
  UserUpdateRequestDTO,
} from './user.dtos';
import { User } from './user.models';

export interface IUserRepository {
  create(data: CreateUserRequestDTO): Promise<void>;
  logIn(input: UserLoginRequestDTO): Promise<UserLoginResponseDTO>;
  logOut(): Promise<void>;
  recoveryPassword(input: RecoveryPasswordRequestDTO): Promise<void>;
  confirmRecoveryPassword(input: ConfirmRecoveryPasswordRequestDTO): Promise<void>;
  remove(input: RemoveRequestDTO): Promise<void>;
  checkStatus(): Promise<User>;
  updateUser(params: UserUpdateRequestDTO): Promise<void>;
}

export class UserRepository implements IUserRepository {
  private httpClient: IHttpClient;
  private endpoint: string = '/users';

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async get<User>(id: string): Promise<User> {
    return this.httpClient.get<User>(`${this.endpoint}/${id}`);
  }

  async create(data: CreateUserRequestDTO): Promise<void> {
    return this.httpClient.post(`${this.endpoint}`, data);
  }

  async logIn(input: UserLoginRequestDTO): Promise<UserLoginResponseDTO> {
    return this.httpClient.post(`/login`, input);
  }

  async logOut(): Promise<void> {
    return this.httpClient.post(`/logout`);
  }

  async recoveryPassword(input: RecoveryPasswordRequestDTO): Promise<void> {
    return this.httpClient.post(`${this.endpoint}/forgot-password`, input);
  }

  async confirmRecoveryPassword(input: ConfirmRecoveryPasswordRequestDTO): Promise<void> {
    return this.httpClient.put(`${this.endpoint}/confirm-password-recovery`, input);
  }

  async remove(input: RemoveRequestDTO): Promise<void> {
    return this.httpClient.post(`${this.endpoint}/drop-out`, input);
  }

  async checkStatus(): Promise<User> {
    return this.httpClient.get(`/status`);
  }

  async updateUser(params: UserUpdateRequestDTO): Promise<void> {
    return this.httpClient.put(`${this.endpoint}`, params);
  }
}
