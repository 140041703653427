import { useContext, useState } from 'react';
import Block from '../Block/Block';
import CustomLink from '../CustomLink/CustomLink';
import Input from '../Input/Input';
import Spinner from '../Spinner/Spinner';
import Text from '../Text/Text';
import { useFormik } from 'formik';
import Button from '../Button/Button';
import { routes } from '../../routes';
import Checkbox from '../Checkbox/Checkbox';
import { LogoImg } from './NewsLetter.styles';
import comet from '../../assets/images/footer/comet.webp';
import Form from '../Form/Form';
import { ServicesContext } from '../../contexts/ServicesContext';
import { useDispatch } from 'react-redux';
import SnackbarAction from '../../redux/snackbar/snackbar.actions';

export interface FormikState {
  email: string;
  acceptedPolicies: any;
}

const Newsletter = () => {
  const { newsLetterService } = useContext(ServicesContext)!;
  const [isLoading, setIsloading] = useState<boolean>(false);
  const initialState: FormikState = {
    email: '',
    acceptedPolicies: false,
  };
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      try {
        setIsloading(true);
        await newsLetterService.registerClient({ email: values.email });
      } catch (error) {
        console.error(`There was an error registering newsletter client: ${error}`);
        dispatch(SnackbarAction.error((error as Error).message));
      }
      setIsloading(false);
      dispatch(SnackbarAction.success('Operacion existosa, revisa tu bandeja de entrada'));
    },
    validate: (values) => {
      const errors: Partial<FormikState> = {};
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email invalido';
      }
      if (!values.acceptedPolicies) {
        errors.acceptedPolicies = 'Debes aceptar las politicas';
      }
      return errors;
    },
  });

  if (formik.isSubmitting || isLoading) {
    return <Spinner top="25%" />;
  }
  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Block display="flex" justify="center" customStyles={{ padding: '3rem 1rem' }}>
        <Block display="flex" direction="column" customStyles={{ maxWidth: '500px' }}>
          <Block mb="l" pr="s" pl="s">
            <Block display="flex" position="relative">
              <Text color="black" as="p" textAlign="center" weight="semiBold" customStyles={{ fontSize: '1.48rem' }}>
                Suscríbete a nuestra newsletter
              </Text>
              <Block display="flex" align="center" justify="flex-start">
                <LogoImg src={comet} alt="sinag-cometa" />
              </Block>
            </Block>
            <Text>
              Obten un{' '}
              <Text as="strong" weight="bold" color="black" fontSize="l">
                20%
              </Text>{' '}
              de descuento al suscribirte
            </Text>
          </Block>
          <Block display="flex" width="100%" align="center" mb="l">
            <Checkbox
              onChange={formik?.handleChange}
              name="acceptedPolicies"
              value={formik.values.acceptedPolicies}
              checked={formik.values.acceptedPolicies}
              inputSize="xs"
            />
            <Block display="flex" flexWrap="wrap" ml="s">
              <Text fontsize="xs" as="a" color="black">
                Confirmo que he leído y acepto la
              </Text>
              <CustomLink fontSize="s" hoverColor="darkSilver" to={routes.PRIVACY_POLICY} weight="medium" ml="s">
                Política de Privacidad
              </CustomLink>
            </Block>
          </Block>
          <Block display="flex" justify="center" align="center" width="100%" pt="s" pb="s" mb="m">
            <Block display="flex" direction="column" width="100%" customStyles={{ maxWidth: '300px' }}>
              <Input
                onChange={formik.handleChange}
                type="email"
                name="email"
                value={formik.values.email}
                variant="borderless"
                inputSize="m"
                placeholder="Introduce to email"
                focusable={false}
                width="100%"
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <Block display="flex" width="100%" justify="center">
                  <Text fontsize="s" color="red">
                    {formik.errors.email as string}
                  </Text>
                </Block>
              )}
            </Block>
          </Block>
          <Block display="flex" justify="center" pt="m" pb="m" width="100%">
            <Button
              text="Subscríbete"
              type="submit"
              disabled={isButtonDisabled}
              variant="default"
              color="xLightSilver"
              size="m"
              id="home_newsletter_submit_btn"
            />
          </Block>
        </Block>
      </Block>
    </Form>
  );
};

export default Newsletter;
