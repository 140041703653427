export class StorageHandler {
  static save(key: string, value: any) {
    try {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(key, serializedState);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  static retrieve(key: string) {
    try {
      const state = localStorage.getItem(key);
      if (state === null) return undefined;
      return JSON.parse(state);
    } catch (error: any) {
      return {
        error: error?.message,
      };
    }
  }

  static clear(key?: string) {
    try {
      return key ? localStorage.removeItem(key) : localStorage.clear();
    } catch (error: any) {
      return {
        error: error?.message,
      };
    }
  }
}
