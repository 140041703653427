import { Block, Form, Stepper } from '../../components';
import { FormikValues } from 'formik';
import { GuessData, CustomerData, Payment, DeliveryInfo, DeliveryOptions } from './components';
import Carousel from 'react-bootstrap/Carousel';

export interface CheckoutProps {
  formik: FormikValues;
  showDeliveryInput: boolean;
  isButtonDisabled: boolean;
  discount: number;
  subTotal: number;
  totalToPay: number;
  steps: Array<string>;
  currentStep: number;
  handleSteps: (indx: number) => void;
}

const CheckoutLayout: React.FC<CheckoutProps> = ({ formik, isButtonDisabled, steps, currentStep, handleSteps }) => {
  return (
    <Block width="100%">
      <Stepper activeStep={currentStep} stepLabels={steps} />
      <Form
        onSubmit={formik.handleSubmit}
        display="flex"
        direction="column"
        justify="space-evenly"
        align="center"
        width="100%"
        pt="xl"
        pb="xl"
      >
        <Carousel activeIndex={currentStep} onSelect={handleSteps} interval={null} wrap controls={false} slide={false}>
          <Carousel.Item>
            <Block display="flex" direction="column">
              <CustomerData />
              <GuessData data={formik} handleSteps={handleSteps} currentStep={currentStep} />
            </Block>
          </Carousel.Item>
          <Carousel.Item>
            <Block display="flex" direction="column">
              <DeliveryInfo data={formik} handleSteps={handleSteps} currentStep={currentStep} />
            </Block>
          </Carousel.Item>
          <Carousel.Item>
            <Block display="flex" direction="column">
              <DeliveryOptions data={formik} handleSteps={handleSteps} currentStep={currentStep} />
            </Block>
          </Carousel.Item>
          <Carousel.Item>
            <Block display="flex" direction="column">
              <Payment disabled={isButtonDisabled} handleSteps={handleSteps} currentStep={currentStep} />
            </Block>
          </Carousel.Item>
        </Carousel>
      </Form>
    </Block>
  );
};

export default CheckoutLayout;
