import styled from 'styled-components';

export const Wrapper = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  background-color: ${(p) => p.theme.color.white};
  width: 100%;
  height: 100%;
  min-height: 35vh;
  overflow: auto;
  overflow-y: auto;
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  border-top: ${(p) => !p.isMobile && `1px solid ${p.theme.color.lightSilver}`};
  margin-top: 180px;
  padding: 1rem;

  ${(p) => p.theme.media.tablet`
        margin-top: 140px;
        min-height: 57vh;
    `}
  ${(p) => p.theme.media.desktop`
        margin-top: 140px;
        min-height: 65vh;
    `}
`;
