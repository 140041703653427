import React from 'react';
import { InputBaseProps } from '../../theme';
import { InputContainer, Wrapper } from './Checkbox.styles';
import { Size } from '../../theme';

export interface CheckBoxProps extends InputBaseProps {
  checked?: boolean;
  inputSize?: Size;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => (
  <Wrapper>
    <InputContainer {...props} type="checkbox" />
  </Wrapper>
);

export default React.memo(CheckBox);
