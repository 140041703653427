import mixpanel from 'mixpanel-browser';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { environment, MIXPANEL_LIB_NAME } from '../constants';

interface MixPanelContextType {
  mixpanel: any;
}

const MixPanelContext = createContext<MixPanelContextType | undefined>(undefined);

interface MixPanelProviderProps {
  children: ReactNode;
}

const config = {
  debug: true,
};

function MixPanelProvider({ children }: MixPanelProviderProps) {
  const [isInit, setIsIntit] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        mixpanel.init(environment.MIXPANEL_TOKEN ?? 'b6dea6176e2aa3d2425a29e3ae08a14d');
        mixpanel.identify('3');
        mixpanel.track('PurchaseComplete', {
          cartId: '1234567',
          items: [
            {
              productId: '1234567',
              quantity: 1,
              price: 12.2,
            },
          ],
          total: 12.2,
        });
        mixpanel.track('Prueba', {
          name: 'Test',
          value: 1234567,
        });
        // mixpanel.people.set({
        //   $first_name: 'Leopoldo',
        //   $last_name: 'Romero',
        // });
        // mixpanel.set_config({'persistence': 'localStorage'})
      } catch (e) {
        mixpanel.track(`Unsuccessful register event: ${e}`);
      }
    })();
    return () => {};
  }, []);

  // useEffect(() => {
  //     if (!isInit) {
  //         mixpanel.init(environment.MIXPANEL_TOKEN, config, MIXPANEL_LIB_NAME);
  //         setIsIntit(true);
  //     }
  // }, [])
  // mixpanel.set_config({'persistence': 'localStorage'})
  // mixpanel.track('Signed Up', {
  //     'Signup Type': 'Referral'
  //   })

  return (
    <MixPanelContext.Provider
      value={{
        mixpanel,
      }}
    >
      {children}
    </MixPanelContext.Provider>
  );
}

export { MixPanelContext, MixPanelProvider };
