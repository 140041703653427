import React from 'react';
import { TextProps } from './Text.models';
import { TexContainer } from './Text.styles';

const Text: React.FC<TextProps> = (props) => (
  <TexContainer {...props} as={props?.as ?? 'span'} fontsize={props.fontsize ?? 'm'}>
    {props.children}
  </TexContainer>
);

export default Text;
